import { Box, Button, FormControlLabel, Switch, Table, TableContainer, TableFooter, TableRow, makeStyles } from '@material-ui/core'
import { FilterList, Refresh } from '@material-ui/icons'
import { default as React, useState, useEffect } from 'react'
import { components, useServices } from 'cng-web-lib'
import ExportComponent from 'src/components/table/ExportComponent'
import CngTableBody from 'src/components/table/CngTableBody'
import CngTableHeader from 'src/components/table/CngTableHeader'
import ResultPageTablePagination from './ResultPageTablePagination'
import ViewTableDetails from './ViewTableDetails'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import TranslationText from './TranslationText'
import UserPreferenceApiUrls from 'src/apiUrls/UserPreferenceApiUrls'

const {
	CngDialog,
	button: { CngPrimaryButton }
} = components

const toolbarStyles = makeStyles((theme) => ({
	buttonIcons: {
		'& .svg-inline--fa': { fontSize: 'inherit' }
	}
}))

const useStyles = makeStyles((theme) => ({
	div: {
		'& .MuiTable-root': {
			borderSpacing: '0 5px',
			borderCollapse: 'separate',
			'& .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head': {
				backgroundColor: '#fafbfc',
				borderBottom: 'none',
				'&:nth-child(1)': {
					width: 'auto !important'
				},
				'&:nth-child(n + 2):nth-last-child(n + 2)': {
					width: 'auto !important'
				}
			},
			'& .MuiTableRow-root[index]': {
				'&:nth-child(even)': {
					backgroundColor: '#fafbfc'
				},
				'& td[class*="MuiTableCell-root"]': {
					border: '1px solid #f1f4f9 !important',
					borderLeft: 'none !important',
					borderRight: 'none !important',
					'&:nth-child(1)': {
						borderLeft: '1px solid #f1f4f9 !important',
						borderRadius: '5px 0 0 5px !important',
						width: 'auto !important'
					},
					'&:nth-child(n + 2):nth-last-child(n + 2)': {
						width: '20% !important'
					},
					'&:nth-last-child(1)': {
						borderRight: '1px solid #f1f4f9 !important',
						borderRadius: '0 5px 5px 0 !important',
						'& .MuiIconButton-root': {
							border: '1px solid #f4f4f7',
							borderRadius: '10px'
						}
					}
				}
			}
		},
		'& .MuiFormControl-root': {
			'& .MuiFormLabel-root': {
				marginTop: '-40px',
				marginLeft: '10px'
			},
			'& .MuiInputLabel-shrink': {
				transform: 'translate(14px, 7px) scale(0.75)'
			},
			'& .MuiInputBase-root': {
				marginTop: '-20px',
				'& .MuiOutlinedInput-input': {
					padding: '0px 0px 5px 25px'
				},
				'& .MuiOutlinedInput-notchedOutline': {
					borderColor: 'white',
					borderBottomColor: 'black'
				}
			}
		},
		'& .vs-table-cell-padding-filter': {
			backgroundColor: 'white !important'
		}
	},
	trashIcon: {
		color: theme.palette.text.error,
		width: theme.spacing(2),
		height: theme.spacing(2)
	}
}))

function MGTTTableView(props) {
	const toolbarClasses = toolbarStyles()
	const translatedTextObject = TranslationText()
	const classes = useStyles()
	const [isDialogOpen, setDialogOpen] = useState(false)
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [userProfile, ,] = useState([])
	const [user, ,] = useState([])
	const [viewData, setViewData] = useState()
	const [timeZone, setTimeZone] = useState()
	const [callTzApi, setCallTzApi] = useState(false)
	const { fetchRecords } = useServices()
	const [exportColumns, ,] = useState([
		{
			field: "sigaDocId",
			title: translatedTextObject.ttNo,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "ttCreatedDate",
			title: translatedTextObject.ttCreatedDate,
			show: true,
			active: false,
			order: 'desc',
			value: ''
		},
		{
			field: "ttUpdatedDate",
			title: translatedTextObject.ttUpdatedDate,
			show: true,
			active: false,
			order: 'desc',
			value: ''
		},
		{
			field: "statuses",
			title: translatedTextObject.statuses,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "containerNo",
			title: translatedTextObject.containerNo,
			show: true,
			active: false,
			order: 'desc',
			value: ''
		},
		{
			field: "psaId",
			title: translatedTextObject.psaId,
			show: true,
			active: false,
			order: 'desc',
			value: ''
		},
		{
			field: "containerCreatedDate",
			title: translatedTextObject.containerCreatedDate,
			show: true,
			active: false,
			order: 'desc',
			value: ''
		},
		{
			field: "containerUpdatedDate",
			title: translatedTextObject.containerUpdatedDate,
			show: true,
			active: false,
			order: 'desc',
			value: ''
		},
		{
			field: "psaApproval",
			title: translatedTextObject.psaApproval,
			show: true,
			active: false,
			order: 'desc',
			value: ''
		},
		{
			field: "origin",
			title: translatedTextObject.origin,
			show: true,
			active: false,
			order: 'desc',
			value: ''
		},
		{
			field: "destination",
			title: translatedTextObject.destination,
			show: true,
			active: false,
			order: 'desc',
			value: ''
		},
		{
			field: "priority",
			title: translatedTextObject.priority,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "originatingVessel",
			title: translatedTextObject.originatingVessel,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "containerSize",
			title: translatedTextObject.containerSize,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "containerType",
			title: translatedTextObject.containerType,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "imoNo",
			title: translatedTextObject.imoNo,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "cargoUnCode",
			title: translatedTextObject.cargoUnCode,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "temperature",
			title: translatedTextObject.temperature,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "inYard",
			title: translatedTextObject.inYard,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "noOfShifting",
			title: translatedTextObject.noOfShifting,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "category",
			title: translatedTextObject.category,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "onHold",
			title: translatedTextObject.onHold,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "weight",
			title: translatedTextObject.weight,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "yardArea",
			title: translatedTextObject.yard,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "eir",
			title: translatedTextObject.eir,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "driverName",
			title: translatedTextObject.driverName,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "driverId",
			title: translatedTextObject.driverId,
			show: true,
			active: false,
			value: ''
		},
		{
			field: "licensePlate",
			title: translatedTextObject.licensePlate,
			show: true,
			active: false,
			order: 'desc',
			value: ''
		}
	])

	useEffect(() => {
		fetchRecords.execute(
			UserPreferenceApiUrls.TZ_GET,
			undefined,
			(data) => {
				console.log('Timezone is: ', JSON.stringify(data.timezone))
				setTimeZone(data.timezone)
				setCallTzApi(true)
			},
			(error) => {
				console.log(error)
			}
		)
	}, [])

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const onRowClick = (id) => () => {
		setDialogOpen(true)
		let newData = [...props.data]
		const index = newData.findIndex((item) => item.id === id)
		const updatedData = newData[index]
		setViewData(updatedData)
	}

	const onViewIconClick = (id) => () => {
		setDialogOpen(true)
		let newData = [...props.data]
		const index = newData.findIndex((operator) => operator.id === id)
		const operator = newData[index]
		setViewData(operator)
	}

	const onCloseDialogClick = () => {
		setDialogOpen(false)
	}

	const showArchivedChange = (e) => {
		props.setShowArchivedField(e.target.checked)

		// reset all checkbox and filter
		props.setCheckedRows([])
		const newData = props.data.map(item => ({ ...item, checked: false }))
		props.setData(newData)
		props.setSearchData([])
		props.setEmptyData(false)

		props.setColumns(props.columns.map(column => ({ ...column, value: '' })))
	}

	const onSortClick = (fieldName) => () => {
		props.setColumns(
			props.columns.map((column) => ({
				...column,
				active: fieldName === column.field,
				order:
					(fieldName === column.field &&
						(column.order === 'desc' ? 'asc' : 'desc')) ||
					undefined
			}))
		)

		const dataToSort = props.searchData.length > 0 ? props.searchData : props.data

		const sortedData = dataToSort.slice().sort(
			comparator(
				fieldName,
				props.columns.find((column) => column.field === fieldName).order === 'desc'
			)
		).map((v, index) => ({
			...v,
			seqNo: index + 1
		}))

		if (props.searchData.length > 0) {
			props.setSearchData(sortedData)
		} else {
			props.setData(sortedData)
		}
	}

	const comparator = (fieldName, isDesc) => (a, b) => {
		const valueA = a[fieldName]
		const valueB = b[fieldName]

		if (valueA === null && valueB === null) {
			return 0
		}
		if (valueA === null) {
			return isDesc ? 1 : -1
		}
		if (valueB === null) {
			return isDesc ? -1 : 1
		}

		if (valueA < valueB) {
			return isDesc ? 1 : -1
		}
		if (valueA > valueB) {
			return isDesc ? -1 : 1
		}
		return 0
	}

	const onRowCheck = (id) => (e) => {
		let newData = []
		if (props.searchData.length === 0) {
			newData = [...props.data]
		} else {
			newData = [...props.searchData]
		}
		const index = newData.findIndex((driver) => driver.id === id)
		const driver = newData[index]
		newData[index] = { ...driver, checked: !driver.checked }

		if (props.searchData.length === 0) {
			props.setData(newData)
		} else {
			props.setSearchData(newData)
		}
		props.setCheckedRows(newData.filter(row => row.checked))
	}

	const onHeaderCheck = (event) => {
		let newData = []
		if (props.searchData.length === 0) {
			newData = [...props.data]
		} else {
			newData = [...props.searchData]
		}
		newData = newData.map((item) => ({
			...item,
			checked: event.target.checked
		}))

		if (props.searchData.length === 0) {
			props.setData(newData)
		} else {
			props.setSearchData(newData)
		}
		props.setCheckedRows(newData.filter(row => row.checked))
	}

	const onFilterTextChange = (name) => (event) => {
		let newColumns = []
		props.columns.map(column => {
			if (column.field === name) {
				column.value = event.target.value
			}
		})
		newColumns = [...props.columns]
		props.setColumns(newColumns)
	}

	const onClickFilter = () => {
		let newData = [...props.data]
		props.columns.map(column => {
			if (column.value != '') {
				newData = newData.filter(d => (d[column.field] != null ? d[column.field].toString().toLowerCase().includes(column.value.toLowerCase()) : null))
			}
		})

		if (newData.length == 0)
			props.setEmptyData(true)
		else {
			props.setEmptyData(false)
		}
		props.setSearchData(newData)
		setPage(0)
	}

	return (
		<div>
			<FormControlLabel
				control={
					<Switch
						checked={props.showArchivedField}
						onChange={showArchivedChange}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				}
				label={!props.isBatch ? "Show Archived Only" : "Failed TTs"}
			/>
			{(props.isBatch && props.showArchivedField) && <CngPrimaryButton
				onClick={props.onRetriggerButtonClick}
			>Re-trigger
			</CngPrimaryButton>}
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Box>
					<Button className={'ng-button-outline'} startIcon={<FilterList />} variant='outlined' onClick={onClickFilter}>
						Filter
					</Button>
				</Box>
				<div style={{ padding: '5px' }} />
				<Box>
					<Button className={'ng-button-outline'} startIcon={<Refresh />} variant='outlined' onClick={props.manualRefresh}>
						Refresh
					</Button>
				</Box>
				<div style={{ padding: '5px' }} />
				<ExportComponent
					showNotification={props.showNotification}
					toolbarClasses={toolbarClasses}
					columns={exportColumns}
					multiSelect={true}
					data={props.checkedRows.length > 0 ? props.checkedRows : props.data}
					dataType={'mgtt'}
					user={user}
					userProfile={userProfile}
				/>
			</div>
			<Box m='8px' className={classes.div}>
				<TableContainer>
					<Table>
						<CngTableHeader
							// vessels={vessels}
							showSeqNo={false}
							data={props.searchData.length == 0 && !props.emptyData ? props.data : props.searchData}
							onHeaderCheck={onHeaderCheck}
							columns={props.columns}
							onSortClick={onSortClick}
							viewOnly={props.viewOnly}
							onClickFilter={onClickFilter}
							onFilterTextChange={onFilterTextChange}
							showFilter={true}
						/>
						<CngTableBody
							// vessels={vessels}
							data={props.searchData.length == 0 && !props.emptyData ? props.data : props.searchData}
							page={page}
							showSeqNo={false}
							rowsPerPage={rowsPerPage}
							columns={props.columns}
							onRowCheck={onRowCheck}
							onRowClick={props.viewOnly ? onRowClick : (event) => { }}
							onViewIconClick={onViewIconClick}
							user={user}
							userProfile={userProfile}
							viewOnly={props.viewOnly}
						/>
						<TableFooter>
							<TableRow>
								<ResultPageTablePagination
									// recordLength={vessels.length}
									recordLength={props.searchData.length == 0 ? props.data.length : props.searchData.length}
									rowsPerPage={rowsPerPage}
									page={page}
									noOfColumns={11}
									handleChangePage={handleChangePage}
									handleChangeRowsPerPage={handleChangeRowsPerPage}
								/>
							</TableRow>
						</TableFooter>
						{(!props.viewOnly && !props.isBatch) && <CngPrimaryButton
							onClick={props.onArchivedButtonClick}
						>{props.archivedButtonText}
						</CngPrimaryButton>}
					</Table>
				</TableContainer>
			</Box>
			{props.showDetails && <CngDialog
				customDialogContent={
					<ViewTableDetails.FormBody
						viewData={viewData}
						timezone={timeZone ? timeZone : null}
						callTzApi={callTzApi} />
				}
				dialogTitle={
					<>
						<b>Details</b>
						<CloseDialogIconButton
							onClick={onCloseDialogClick}
						/>
					</>
				}
				open={isDialogOpen}
				fullWidth
				maxWidth='lg'
			/>}
		</div>
	)
}

export default MGTTTableView