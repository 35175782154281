import { Card, CardContent } from '@material-ui/core'
import { useServices } from 'cng-web-lib'
import { default as React, useEffect, useState } from 'react'
import TranslationText from './TranslationText'
import TransshipmentApiUrls from 'src/apiUrls/TransshipmentApiUrls'
import MGTTTableView from './MGTTTableView'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'

function TransporterPage(props) {
	const {
		showNotification
	} = props

	const { fetchRecords } = useServices()
	const translatedTextObject = TranslationText()
	const [checkedRows, setCheckedRows] = useState([])
	const [archivedCheckedRows, setArchivedCheckedRows] = useState([])
	const [data, setData] = useState([])
	const [searchData, setSearchData] = useState([])
	const [searchArchivedData, setSearchArchivedData] = useState([])
	const [loading, setLoading] = useState(true)
	const [refresh, setRefresh] = useState(false)
	const [showArchivedField, setShowArchivedField] = useState(false)
	const [archivedData, setArchivedData] = useState([])
	const [emptyData, setEmptyData] = useState(false)
	const [emptyArchivedData, setEmptyArchivedData] = useState(false)
	const [showDetails, setShowDetails] = useState(false)
	const [columns, setColumns] = useState([
		{
			field: "sigaDocId",
			title: translatedTextObject.ttNo,
			show: true,
			active: true,
			archived: false,
			order: 'asc',
			value: ''
		},
		{
			field: "containerNo",
			title: translatedTextObject.containerNum,
			show: true,
			active: false,
			order: 'desc',
			archived: false,
			value: ''
		},
		{
			field: "priority",
			title: translatedTextObject.priority,
			show: true,
			active: false,
			archived: false,
			order: 'desc',
			value: ''
		},
		{
			field: "containerSize",
			title: translatedTextObject.containerSize,
			show: true,
			active: false,
			archived: false,
			value: ''
		},
		{
			field: "driverName",
			title: translatedTextObject.driverName,
			show: true,
			active: false,
			archived: false,
			value: ''
		},
		{
			field: "licensePlate",
			title: translatedTextObject.licensePlate,
			show: true,
			active: false,
			order: 'desc',
			archived: false,
			value: ''
		},
		{
			field: "onHold",
			title: translatedTextObject.onHold,
			show: true,
			active: false,
			archived: false,
			value: ''
		},
		{
			field: "statuses",
			title: translatedTextObject.statuses,
			show: true,
			active: false,
			archived: false,
			value: ''
		}
	])

	useEffect(() => {
		let newArchivedData = []
		let newData = []
		fetchRecords.execute(
			TransshipmentApiUrls.GET_ALL_TT_FOR_TRANSPORTERS, false,
			(data) => {
				setLoading(false)
				data.map(d => {
					if (d.sigaDocId === null) {
						d.sigaDocId = 'No TT Found'
					}
					if (d.archived) {
						newArchivedData.push(d)
					} else {
						d.checked = false
						newData.push(d)
					}
				})
				sortData(newData)
				setArchivedData(newArchivedData)
			}
		)
		setShowDetails(false)
	}, [refresh])

	const sortData = (newData) => {
		setLoading(true)
		const sortedData = newData.slice().sort(
			comparator("sigaDocId", columns.find((column) => column.field === "sigaDocId").order === 'desc')
		).map((v, index) => ({
			...v,
			seqNo: index + 1
		}))

		setData(sortedData)
		setLoading(false)
	}

	const comparator = (fieldName, isDesc) => (a, b) => {
		const valueA = a[fieldName]
		const valueB = b[fieldName]

		if (valueA === null && valueB === null) {
			return 0
		}
		if (valueA === null) {
			return isDesc ? 1 : -1
		}
		if (valueB === null) {
			return isDesc ? -1 : 1
		}

		if (valueA < valueB) {
			return isDesc ? 1 : -1
		}
		if (valueA > valueB) {
			return isDesc ? -1 : 1
		}
		return 0
	}

	const manualRefresh = () => {
		setRefresh(!refresh)
		setLoading(true)
		let newArchivedData = []
		let newData = []

		const onSuccess = (data) => {
			data.map(d => {
				if (d.archived) {
					newArchivedData.push(d)
				} else {
					newData.push(d)
				}
			})
			columns.map(column => {
				if (column.value != '') {
					newData = newData.filter(d => (d[column.field] != null ? d[column.field].toString().toLowerCase().includes(column.value.toLowerCase()) : null))
				}
			})
			if (searchData.length != 0) {
				setSearchData(newData)
				setEmptyData(false)
			} else {
				setData(newData)
			}
			if (searchArchivedData.length != 0) {
				setSearchArchivedData(newArchivedData)
				setEmptyArchivedData(false)
			} else {
				setArchivedData(newArchivedData)
			}

			setLoading(false)
		}

		const onError = (error) => {
			console.log('connection-request error', error.message)
			setLoading(false)
		}

		fetchRecords.execute(
			TransshipmentApiUrls.GET_ALL_TT_FOR_TRANSPORTERS,
			false,
			onSuccess,
			onError
		)
	}

	return (
		<Card>
			<CardContent>
				<CngBackdrop loading={loading} />

				{!showArchivedField && <MGTTTableView
					showNotification={showNotification}
					data={data}
					columns={columns}
					archivedButtonText={'Archive'}
					viewOnly={true}
					setColumns={setColumns}
					setData={setData}
					setCheckedRows={setCheckedRows}
					checkedRows={checkedRows}
					emptyData={emptyData}
					setEmptyData={setEmptyData}
					searchData={searchData}
					setSearchData={setSearchData}
					manualRefresh={manualRefresh}
					showArchivedField={showArchivedField}
					setShowArchivedField={setShowArchivedField}
					showDetails={showDetails}
				/>
				}
				{showArchivedField && <MGTTTableView
					showNotification={showNotification}
					data={archivedData}
					columns={columns}
					archivedButtonText={'Unarchive'}
					viewOnly={true}
					setColumns={setColumns}
					setData={setArchivedData}
					setCheckedRows={setArchivedCheckedRows}
					checkedRows={archivedCheckedRows}
					emptyData={emptyArchivedData}
					setEmptyData={setEmptyArchivedData}
					searchData={searchArchivedData}
					setSearchData={setSearchArchivedData}
					manualRefresh={manualRefresh}
					showArchivedField={showArchivedField}
					setShowArchivedField={setShowArchivedField}
					showDetails={showDetails}
				/>
				}
			</CardContent>
		</Card >
	)
}

export default TransporterPage